.card-header {
    padding: 1rem 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0px solid rgba(0, 0, 0, 0.125);
    background: transparent;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    // padding-top: 0.75rem;
}

.card-title {
    color: #3e4676;
    font-size: 0.9375rem;
    line-height: 1.5;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 0.9375rem;
}

