.modal-backdrop.show {
    background: rgba(000,000,000,0.5);
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 999999;
}

.modal.show {
    display: block;
    max-width: 500px;
    width: calc(100% - 10px);
    height: auto;
    margin: auto;
    z-index: 9999999;
    position: absolute;
    background: #FFF;
    border-radius: 10px;
    padding: 1rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #1D1E7A;
}

.modal-header .close {
    background: none;
    border: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #1D1E7A;
    font-size: 1.5rem;
    line-height: 1;
}

.modal-info-title{
    font-weight: 500;
    margin-top:1rem;
}

.modal-info-content{
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}

.modal-counter{
    display: flex;
    align-items: center;
}
.modal-counter span{
    height: 1.5rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d6de;
}

.modal-counter i:first-child{
    width: 1.6rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d6de;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

.modal-counter i:last-child{
    width: 1.6rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d6de;
    border-left: 0;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}
