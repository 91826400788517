.btn-theme {
    background: #3e4676;
    color: #FFF;
    border: none;
    padding: 0 3rem;
    border-radius: 0.357rem;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition: 0.3s;
    min-height: 3rem;
    display: flex;
    align-items: center;
    svg{
        margin-right:0.4rem;
    }
    &:hover{
        box-shadow: 0 8px 25px -8px #7367f0;
    }
}

.btn-add {
    background: #3e4676;
    color: #FFF;
    border: none;
    border-radius: 0.357rem;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition: 0.3s;
    min-height: 3rem;
    display: flex;
    align-items: center;
    text-align: center;
    span{
        margin: auto;
    }
}

.btn-theme.btn-small {
    padding        : 0 1.5rem;
    font-size      : 0.9rem;
    min-height     : 2.4rem;
}

.btn-theme.btn-delete {
    background     : #ea5455;
}

.btn-wrap {
    padding:0 1.5rem;
    margin-top:2rem;
}

.button-wrapper{
    margin-top:1rem;
}

