body {
    margin: 0;
    font-family: 'Rubik', sans-serif, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f8f8f8;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3e4676;
    text-align: left;
    overflow: initial !important;
}

//why do we need this? - take it out and test
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
    font-size: 1.64063rem;
    color: #212529;
    margin: 1.5rem 0;
}

hr {
    display: block;
    border-bottom: 1px solid #F5F5F5;
    background-color: #EAEAEA;
    border-top: none;
    margin-bottom: 16px;
    margin-top: 16px;
}

table {
    display        : table;
    box-sizing     : border-box;
    text-indent    : initial;
    border-spacing : 2px;
    border-color   : #fafafa;
}
tr {
    border-color   : #fafafa;
    border-style   : solid;
    border-width   : 0;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #636363;
}

* {
    box-sizing: border-box;
}

