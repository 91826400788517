@media(max-width:768px){
    .picker-wrap {
        flex-direction: column;
    }
    .picker {
        flex-direction: column;
        width: 100%;
    }
    .picker__item {
        width         : 100%;
    }
    .picker__item > div {
        padding: 0.75rem 0rem;
    }
}

@media(max-width: 1100px) {
    .section-card__wrapper > div {
        width: 100%;
    }
    .w-3{
        width:100%;
        height: 100%;
    }
    .hour-row{
        width:100%;
    }
    .item-condition-inn {
        display: flex;
        flex-wrap: wrap;
    }
    .item-condition-inn > div {
        width: 100%;
    }
}
@media(max-width:700px) {
    .manifest-alert{
        display: none;
    }
    .header__brand{
        display: none;
    }
    .header__title {
        color: #FFF;
        margin: auto;
    }
    .modal.show {
        display: block;
        max-width: 500px;
        width: calc(100% - 10px);
        height: auto;
        margin: auto;
        z-index: 9999999;
        position: absolute;
        background: #FFF;
        border-radius: 10px;
        padding: 1rem;
        left: 50%;
        top: 20px;
        transform: translate(-50%, 0);
    }
}

@media print {
    // To make app pages look good when printed
    // See https://www.developer.com/languages/10-tips-to-make-your-web-app-pages-more-printer-friendly/
    .bm-menu-wrap { display:none; }
    .header       { display:none; }
    a             { text-decoration:none; }
    .wrapper      { padding: 0 0rem; }
}
