//General styling rules for the TecTransit application
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Rubik:wght@300;400;500&display=swap');

@import 'base';

hr.bm-item {
    margin: 2rem 0;
    color: #fafafa;
}

.header {
    position:fixed;
}

.text-muted {
    color: #6c757d !important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.90625rem + 8px);
    padding: 0.25rem 0.7rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//Styles for Maps
.map-card {
    position       : relative;
    min-width      : 0;
    word-wrap      : break-word;
    background-color: #fff;
    border         : 0 solid rgba(0,0,0,.125);
    border-radius  : .2rem;
    box-shadow     : 0 2px 12px rgb(126 142 177 / 25%);
    /* margin-bottom: 1.5rem; */
}

// Utility styles - to be cleaned up later
.center {
    text-align: center;
}

.mt-minus-one {
    margin-top: -1rem;
}

.m-one {
    margin: 1rem;
}

// Styling regarding TecTransit header
.tt-header {
    height         : 56px;
    background     : #1D1E7A;
    color          : #fff;
    padding        : 1rem;
    margin-bottom  : 1rem;
    // vertical-align: top!important;
    /* align-content: center; */
    /* box-shadow: 0 0 0 2px rgb(0 0 0 / 5%); */
}

.tt-brand {
    position       : absolute;
    width          : 96px;
    height         : 22px;
    left           : 80px;
    top            : 17px;
}

//Styles regarding the Passenger Experience
.jm-map {
    margin-top: 25%;
}

.open #pagewrap-container{
    width: calc(100% - 300px);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px;
    background: #3e4676;
    &__brand{
        color:#FFF;
        font-weight: 500;
    }
    &__title{
        color:#FFF;
    }
    &__logout{
        color:#FFF;
        transition: 0.3s;
        &:hover{
            color:#F3F4F6
        }
    }
}

.wrapper {
    padding: 0 1.5rem;
}

.section-card {
    margin-bottom: 2.2rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
    background: #FFF;
    padding: 1.5rem;
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__title{
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: #1D1E7A;
    }
}

.picker {
    display         : flex;
    align-items     : center;
    flex            : 1;
    position        : relative;
    &__item {
        flex        : 1;
        position    : relative;
        height      : 3.4rem;
        display     : flex;
        align-items : center;
        border          : 1px solid #d8d6de;
        border-radius   : 0.357rem;
        & > div {
            margin-left:  1rem;
            margin-right: 1rem;
            font-family:  'Montserrat', sans-serif;
            font-weight:  500;
        }
    }
}

.picker-wrap {
    display     : flex;
    align-items : center;
}

.alert-message {
    display: flex;
    justify-content: center;
    height: 3rem;
    align-items: center;
    position: fixed;
    background: rgba(238, 236, 253, 1);
    &__inner{
        display: flex;
        align-items: center;
        span{
            display: block;
            margin-left:0.5rem;
        }
    }
}

.input-theme {
    display: block;
    width: 100%;
    height: 2.714rem;
    padding: 0.438rem 1rem;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    &:focus{
        color: #6e6b7b;
        background-color: #fff;
        border-color: #7367f0;
        outline: 0;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        transition: 0.3s;
        &::placeholder{
            padding-left:0.5rem;
        }
    }
    &::placeholder{
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
    }
}

.title-card {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: none;
    padding-bottom: 1.5rem;
    background-color: transparent;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    border-bottom: 1px solid #ebe9f1 !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1.5rem;
    &__name{
        font-weight: 400;
        font-size: 1.2rem;
        color: #5e5873;
        font-family: "Rubik";
    }
}

.section-selector {
    margin-top:1rem;
}

.addressPrediction {
    &__item {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid #ebe9f1;
    }
    &__icon {
        color: #374151;
    }
    &__name {
        color:#374151;
        margin-left:1rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    &__btn {
        margin-top: 1.5rem;
    }
}

.pickup-location {
    position: relative;
    padding-left: 2rem;
    &:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzFEMUU3QSIgZD0ibTEyIDE3IDEtMlY5Ljg1OGMxLjcyMS0uNDQ3IDMtMiAzLTMuODU4IDAtMi4yMDYtMS43OTQtNC00LTRTOCAzLjc5NCA4IDZjMCAxLjg1OCAxLjI3OSAzLjQxMSAzIDMuODU4VjE1bDEgMnpNMTAgNmMwLTEuMTAzLjg5Ny0yIDItMnMyIC44OTcgMiAyLS44OTcgMi0yIDItMi0uODk3LTItMnoiLz48cGF0aCBmaWxsPSIjMUQxRTdBIiBkPSJtMTYuMjY3IDEwLjU2My0uNTMzIDEuOTI4QzE4LjMyNSAxMy4yMDcgMjAgMTQuNTg0IDIwIDE2YzAgMS44OTItMy4yODUgNC04IDRzLTgtMi4xMDgtOC00YzAtMS40MTYgMS42NzUtMi43OTMgNC4yNjctMy41MWwtLjUzMy0xLjkyOEM0LjE5NyAxMS41NCAyIDEzLjYyMyAyIDE2YzAgMy4zNjQgNC4zOTMgNiAxMCA2czEwLTIuNjM2IDEwLTZjMC0yLjM3Ny0yLjE5Ny00LjQ2LTUuNzMzLTUuNDM3eiIvPjwvc3ZnPg0K) no-repeat;
        width: 24px;
        height: 24px;
        transform: translate(0, -50%);
    }
}

.dropoff-location {
    position: relative;
    padding-left: 2rem;
    &:before{
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzFEMUU3QSIgZD0ibTEyIDE3IDEtMlY5Ljg1OGMxLjcyMS0uNDQ3IDMtMiAzLTMuODU4IDAtMi4yMDYtMS43OTQtNC00LTRTOCAzLjc5NCA4IDZjMCAxLjg1OCAxLjI3OSAzLjQxMSAzIDMuODU4VjE1bDEgMnoiLz48cGF0aCBmaWxsPSIjMUQxRTdBIiBkPSJtMTYuMjY3IDEwLjU2My0uNTMzIDEuOTI4QzE4LjMyNSAxMy4yMDcgMjAgMTQuNTg0IDIwIDE2YzAgMS44OTItMy4yODUgNC04IDRzLTgtMi4xMDgtOC00YzAtMS40MTYgMS42NzUtMi43OTMgNC4yNjctMy41MWwtLjUzMy0xLjkyOEM0LjE5NyAxMS41NCAyIDEzLjYyMyAyIDE2YzAgMy4zNjQgNC4zOTMgNiAxMCA2czEwLTIuNjM2IDEwLTZjMC0yLjM3Ny0yLjE5Ny00LjQ2LTUuNzMzLTUuNDM3eiIvPjwvc3ZnPg0K) no-repeat;
        width: 24px;
        height: 24px;
        transform: translate(0, -50%);
    }
}

.order-time {
    display: flex;
    align-items: center;
    font-weight: 500;
    svg {
        color:#1D1E7A;
        margin-right:0.5rem;
    }
}

.header__title a{
    color          : #FFF;
    margin-left    : 0.3rem;
}

.driverInfo {
    display        : flex;
    align-items    : center;
    /*vertical-align : middle;*/
    justify-content: space-between;
    border-bottom  : 1px solid #ebe9f1;
    padding        : 0.3rem 0;
    &__title {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    &__text {
        font-family: 'Montserrat', sans-serif;
        a{
            text-decoration: none;
            font-weight    : 500;
            display        : block;
            color          : #1D1E7A;
        }
    }
}

.fixed-content {
    position: absolute;
    top: 90px;
    right: 0px;
    z-index: 9999;
    max-width: 460px;
    width: 100%;
}

.input-theme-min {
    height: 2.3rem;
}

.user-profile {
    margin-top: 1rem;
    &__info{
        display: flex;
        img{
            border-radius: 50%;
            border: 1px solid #d8d6de;
        }
    }
    &__column {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.field-group {
    margin-bottom: 1rem;
}

.section-card__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    & > div{
        width: 33.3333%;
    }
}

.section-card-inner {
    padding: 1rem;
}

.field-group {
    position: relative;
    input[type="checkbox"] {
        display: none;
        &:checked + label:after {
            content     :'';
            position    : absolute;
            left        : 0;
            top         : 1px;
            width       : 18px;
            height      : 18px;
            background  : url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA5LjUgNy41Jz48cG9seWxpbmUgcG9pbnRzPScwLjc1IDQuMzUgNC4xOCA2Ljc1IDguNzUgMC43NScgc3R5bGU9J2ZpbGw6bm9uZTtzdHJva2U6I2ZmZjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjEuNXB4Jy8+PC9zdmc+") no-repeat;
            background-color: #1D1E7A;
            border-radius: 3px;
            background-size: 10px;
            background-position: 4px 4px;
            box-shadow  : 0 2px 4px 0 rgb(115 103 240 / 40%);
        }
        &:indeterminate + label:after {
            content     :'';
            position    : absolute;
            left        : 0;
            top         : 1px;
            width       : 18px;
            height      : 18px;
            border-radius: 3px;
            background-size: 10px;
            background-position: 4px 4px;
            box-shadow  : 0 0 1px 1px red;
        }
    }
    label.checkbox {
        position: relative;
        padding-left: 1.6rem;
        &:before {
            content:'';
            position: absolute;
            left:0;
            top: 1px;
            width:18px;
            height:18px;
            border: 1px solid #d8d6de;
            border-radius: 3px;
        }
    }
}

.profile-col{
    padding: 1rem 0;
}

.profile-left {
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width:100%;
}

.profile-right  {
    width: 100%;
}

.address-view{
    display: flex;
    align-items: center;
}


.adresslink{
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    color: #1D1E7A;
    margin-left:0.5rem;
}


.address-view svg, .section-card__header svg{
    cursor: pointer;
}

.content-route{
    margin-top:1rem;
}

.route-item {
    display: flex;
    align-items: center;
    padding:0.3rem 0;
    &__name{
        margin-right:1rem;
        font-weight: 500;
        svg{
            margin-right:0.5rem;
        }
    }
}

.manifest-alert {
    display: flex;
    justify-content: center;
    height: 3rem;
    align-items: center;
    position: relative;
    background: rgba(115, 103, 240, 0.12);
    span{
        font-weight: 500;
        margin:0 0.2rem;
    }
}

.manifest {
    &-item{
        display: flex;
        align-items: center;
    }
    &-title{
        font-weight: 500;
        margin-right: 0.7rem;
    }
    &-text{
        text-decoration: none;
        color: #1D1E7A;
        font-family: 'Montserrat', sans-serif;
        a{
            text-decoration: none;
            color: #1D1E7A;
        }
    }
    &-inner{
        display: flex;
        width: 100%;
        border-bottom: 1px solid #D1D5DB;
        padding: 1rem 0;
        &:last-child{
            border:0;
        }
    }
    &-count{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        border: 1px solid #D1D5DB;
        border-radius: 50%;
        line-height: 1;
        margin-right: 1rem;
        margin-top: 0.5rem;
    }
    &-user a img{
        border-radius: 50%;
        margin-right: 0.5rem;
    }
    &-user a{
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        color: rgba(55, 65, 81, 0.9);
    }
    &-user{
        &-item{
            display: flex;
            align-items: center;
            padding: 0.2rem 0;
        }
        &-content{
            margin-left: 3.6rem;
        }
    }
    &-map{
        &-title{
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-right: 0.5rem;
            color:rgba(55, 65, 81, 0.9);
            svg{
                margin-right: 0.2rem;
            }
        }
        &-info{
            font-family: 'Montserrat', sans-serif;
            a{
                text-decoration: none;
                color: #1D1E7A;
            }
        }
    }
}

.manifest-total{
    max-width: 20rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin:auto;
}

.top-1 {
    margin-top:1rem;
}

.wrap-column {
    display: flex;
    flex-wrap:wrap;
    margin-left:-1rem;
    margin-right:-1rem;
    .section-card{
        height: 100%;
    }
}

.w-3 {
    width:33.3333%;
}

.setting {
    margin-top:1rem;
    &__name{
        font-weight: 500;
        width:50%;
    }
    &__content{
        width:50%;
        span{
            font-size: 0.8rem;
            line-height: 1;
        }
    }
    &__item{
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
    }
    &__depot{
        width: 27%;
        .actions{
            display: flex;
            justify-content: space-between;
            button{
                border: none;
                background: none;
                width: 50%;
            }
        }
    }
}

.addressPrediction button {
    background: none;
    border: 2px solid #1D1E7A;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 6px 2px 4px;
    margin-top:1rem;
    margin-right: 0.5rem;
    font-weight: 600;
}

.veh-item {
    display: flex;
    align-items: flex-end;
    max-width: 600px;
    width: 100%;
    justify-content: space-between;
    padding: 0.3rem 0;
    position: relative;
    &:before{
        content:'';
        position: absolute;
        bottom: 7px;
        left:0;
        height: 1px;
        width:100%;
        border-bottom:1px dashed #D1D5DB;
    }
}
.veh-name {
    position: relative;
    background: #FFF;
    font-weight: 500;
    color:#374151;
}
.veh-content {
    position: relative;
    background: #FFF;
    width: 40%;
    > div {
        display: flex;
        align-items: center;
        svg{
            margin-right:0.3rem;
        }
        a{
            color: #6e6b7b;
            text-decoration: none;
            margin-left: 0.04rem;
        }
        &:last-child{
            svg{
                margin-left: 0.25rem;
            }
        }
    }
}

.veh-end {
    width: 100%;
    height: 2px;
    box-shadow: 0px 0px 8px 1px #ccc;
    margin: 2rem 0;
}

.hours-item {
    border: 1px solid #D1D5DB;
    padding: 1.5rem;
    width:100%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    padding-top: 0.7rem;
}

.hours-block {
    display: flex;
    flex-direction: column;
    span{
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #1D1E7A;
        margin-bottom: 0.5rem;
    }
    select{
        border: 1px solid #D1D5DB;
        width: 100%;
        border-radius: 0.5rem;
        position: relative;
        &::-webkit-scrollbar {
            width: 5px;               /* ширина scrollbar */
        }
        &::-webkit-scrollbar-track {
            background: transparent;        /* цвет дорожки */
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(209, 213, 219); /* цвет плашки */
            border-radius   : 20px;                /* закругления плашки */
            border          : 0;                   /* padding вокруг плашки */
        }
        option {
            text-align: center;
            padding:0.2rem 0;
        }
    }
}

.hour-row {
    padding: 0 1rem;
    width:25%;
}

.hours-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
}

.hours-btn {
    width: 100%;
    padding: 0 1rem;
}

.header-condition{
    text-align: center;
    line-height: 1;
}
.header-condition p b{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #1D1E7A;
}
.header-condition p{
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #1D1E7A;
}
.item-condition b{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #1D1E7A;
}
.item-condition{
    margin-bottom:1rem;
}
.item-condition > div:first-child{
    margin-bottom:0.5rem;
}
.condition-item-input{
    max-width: 300px;
    width:100%;
}
.item-condition-inn{
    display: flex;
}
.item-condition-inn > div{
    width:33.3333%;
}
#pagewrap-container{
    transition: all 0.5s ease 0s;
}
.header__brand{
    position: relative;
    left:40px;
}
.inline-flex {
    margin-top: 1rem;
    display: block;
    float: right;
}

/* See https://github.com/nathancahill/split/tree/master/packages/splitjs#direction-default-horizontal */
.split {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    cursor: row-resize;
}

.gutter.gutter-horizontal {
    float: left;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

@import 'menu';
@import 'button';
@import 'modal';
@import 'burgerMenu';
@import 'card';
@import 'table';
@import 'media';
