//Styles regarding the tables
.table thead th {
    vertical-align : bottom;
    border-bottom  : 1px solid #dee2e6;
    padding        : 0.75rem;
}

.table img {
  border-radius    : 50%;
}

.table td {
    padding        : 0.75rem;
    vertical-align : middle;
    border-top     : 1px solid #dee2e6;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #fafafa;
}

.table-theme {
    margin:1.5rem 0;
    padding:1.5rem 0;
    thead {
        background: #f3f2f7;
        border-bottom: 2px solid #ebe9f1;
        border-top: 1px solid #ebe9f1;
        th {
            font-size: 0.8rem;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            color:#6e6b7b;
        }
    }
    tbody {
        td {
            color:#6e6b7b;
            font-family: 'Montserrat', sans-serif;
            line-height: 1.1;
            font-size: 0.9rem;
            letter-spacing: 0.5px;
            a{
                font-weight: 500;
                color: #1D1E7A;
                text-decoration: none;
                display: block;
                line-height: 1.1;
            }
            span{
                font-weight: 500;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                border-radius: 10rem;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                &.finished{
                    background-color: rgba(40, 199, 111, 0.12);
                    color: #28c76f !important;
                }
                &.cancelled{
                    background-color: rgba(115, 103, 240, 0.12);
                    color: #7367f0 !important;
                }
            }
        }
        tr {
            border-bottom:1px solid #ebe9f1;
            td:first-child{
                font-weight: 500;
            }
        }
    }
}

.table-theme td svg{
    margin-right:0.3rem;
}

.thcenter {
    display: flex;
    align-items: center;
    svg{
        margin-right:0.3rem;
    }
}

.table {
    border-spacing: 0;
    width:100%;
}

.table thead th > div{
    display: flex;
    align-items: center;
    svg{
        margin-right:0.1rem;
    }
}
.table-responsive {
    position: relative;
    overflow: auto;
}
