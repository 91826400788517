//Styles regarding the Overlay when BM menu is active
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

//Styles regarding the floating hamburger button in the main interface
.bm-burger-button {
    position:   fixed;
    width:      30px;
    height:     22px;
    left:       24px;
    top:        17px;
}

.bm-burger-bars {
    background: #ffffff;
}

.bm-burger-bars-hover {
    background: #EAEAEA;
}

// Styles regarding the BM wrap
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

// Styles regarding the BM close x button
.bm-cross {
    background: #bdc3c7;
}

.bmMorphShape {
    fill: #000000;
}

//Styles regarding the header part of the hamburger menu
.bmMenu {
    background: #F3F4F6;
    padding:    1rem 1rem 0;
    font-size:   1.15rem;
    min-width: 250px;
    // max-width: 250px;
    transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
    border-right: 0;
    box-shadow: 0 0 2rem 0 rgb(0 0 0 / 5%);
    z-index: 1;
}

.bmMenuBrand {
    padding: 1rem;
    background: #fff;
    font-size: 1.175rem;
    font-weight: 500;
    color: #212529;
    width: 100%;
    display: block;
    text-decoration: none;
}

.bmCrossButton {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 2rem;
    top: 2rem;
}

.bmUserTitle {
    display: block;
    padding: 2.5rem 1rem 1rem;
    background: #fff;
    color: #153d77;
    text-align: center;
}

.bmUserTitle img {
    width: 64px;
    height: 64px;
    margin-bottom: 0.5rem !important;
    border-radius: 50% !important;
    vertical-align: middle;
    border-style: none;
    display: block;
    margin-left: 35%;
}

.bmUserTitle hr {
    margin: 2rem 0;
    color: #fafafa;
}

.bmMenuTitle {
    color: #191919;
    font-weight: 700;
    font-size: 1.25rem;
}




//Styles regarding the section titles of the BM menu
.bm-list-title {
    padding: 0.65rem 0.75rem;
    margin: 1.5rem 0.5rem;
    color: #646464;
    font-weight: 700;
    font-size: 1rem;
}



//Styles regarding the listed items of the BM menu
.bmItem {
    display: inline-block,
}

.bmItemList {
    color: #b8b7ad;
    padding: 0.8em;
}

.bmItemLink {
    padding: 0.65rem 0.75rem;
    margin: 1.5rem 0.5rem;
    color: #646464;
    font-weight: 500;
    background: transparent;
    border-radius: 0.2rem;
    // transition: background .1s ease-in-out;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    display: block;
}

.bmItemLink .fa {
    display: inline;
}

.bmItemLink a {
    display: inline;
    text-decoration: none;
    color: #646464;
}




//Styles regarding the footer of the BM Menu
.bm-menu-footer {
    font-size: 1rem;
    display: inline;
    padding: 0rem 1rem;
    margin: 0.25rem 1rem;
    display: inline;
    text-decoration: none;
    color: #646464;
    // margin-top: 1rem;
}

// .bm-menu-footer a {
//   padding: 0rem 1rem;
//   margin: 0.25rem 1rem;
//   display: inline;
//   text-decoration: none;
//   color: #646464;
// }

.bm-menu-wrap{
    background: #F3F4F6;
}

.bm-item-list .profile{
    border-bottom  : 1px solid #D1D5DB;
    padding        : 0.7rem 0;
    display        : flex !important;
    align-items    : center;
    &__image {
        border-radius: 50%;
    }
    &__username {
        font-weight: 500;
        margin-left: 10px;
        color:#1D1E7A;
    }
}

.bm-menu-wrap {
    z-index: 99999 !important;
}

.bm-menu-wrap{
    box-shadow: -2px -2px 20px 0px #bfbfbf;
}

