.menu {
    padding: 1rem;
    &::-webkit-scrollbar {
        width: 5px;               /* ширина scrollbar */
    }
    &::-webkit-scrollbar-track {
        background: transparent;        /* цвет дорожки */
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(209, 213, 219);   /* цвет плашки */
        border-radius: 20px;       /* закругления плашки */
        border: 0;  /* padding вокруг плашки */
    }
    &__item{
        margin-top: 0.5rem;
    }
    &__link{
        text-decoration: none;
        color:rgba(55, 65, 81, 1);
        line-height: 1rem;
        font-weight: 500;
        font-size: 0.9rem;
        display:flex;
        align-items: center;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        height:3rem;
        border-radius: 0.25rem;
        transition: 0.3s;
        &.active{
            background: rgba(209, 213, 219);
        }
        &:hover{
            background: rgba(209, 213, 219);
            color:rgba(55, 65, 81, 0.9);
        }
        &.menu__subtitle{
            background: transparent;
            &:hover{
                background: transparent;
            }
        }
    }
    &__name{
        display: block;
        line-height: 1rem;
        font-size: 0.9rem;
        margin-left: 0.7rem;
        font-weight: 400;
        letter-spacing: 0.1px;
    }
    &__list-title{
        font-weight: 500;
        font-size: 1.1rem;
        margin: 1rem 0.75rem;
    }
    &__subtitle{
        &-item{
            padding-right: 3rem;
            padding-left: 2.3rem;
            .menu__link{
                font-weight: 300;
            }
        }
    }
    &__footerLink {
        display: flex !important;
        flex-direction: column;
        border-top: 1px solid #D1D5DB;
        margin-top:0.5rem;
        padding-top:0.5rem;
    }
}

